@import '../../styles/core.css';

.root {
  & :global {
    .swiper-wrapper {
      box-sizing: border-box;
    }

    .swiper-slide {
      height: auto !important;
    }

    .carousel-control {
      position: absolute;
      top: 50%;
      z-index: 100;
      border: 1px solid var(--default-grey-20);
      background-color: var(--default-white-a-50);
    }

    .carousel-control--next {
      right: 0;
      transform: translate(0, -50%);
    }

    .carousel-control--prev {
      left: 0;
      transform: translate(0, -50%);
    }
  }
}

.hovered {
  z-index: 300;
}

.root {
  &.pagination {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    & :global {
      .swiper-wrapper {
        height: calc(100% - 20px);
      }

      .swiper-pagination {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        justify-content: center;
        align-items: center;
      }

      .swiper-pagination-bullet {
        position: relative;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--default-grey-40);

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 12px;
          height: 12px;
          transform: translate(-50%, -50%);
        }
      }

      .swiper-pagination-bullet-active {
        background-color: var(--priority-primary-80);
      }
    }
  }
}

.control {
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: var(--border-radius-default);
  background-color: var(--default-white-a-50);
}

.prev {
  transform: rotate(180deg);
}

.dot {
  width: 8px;
  height: 8px;
  padding: 2px;
  border-radius: 50%;
  background-color: var(--default-grey-40);
}

.dotActive {
  background-color: var(--priority-primary-80);
}

@media (--screen-xs) {
  .root {
    &.pagination {
      gap: 18px;

      & :global {
        .swiper-wrapper {
          height: calc(100% - 26px);
        }
      }
    }
  }
}
