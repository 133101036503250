@import '../../../../shared/styles/core.css';

.root {
  & :global {
    & .modal__panel {
      padding: 32px 24px;
      background-color: var(--default-grey-10);
    }
  }
}

.root {
  &.withBanner {
    & :global {
      .modal__panel {
        padding: 0;
      }
    }
  }
}

.loader {
  position: fixed;
}

@media (--screen-lg) {
  .root {
    & :global {
      & .modal__panel {
        max-width: 580px;
        height: auto;
        padding: 40px;
      }
    }

    &.withBanner {
      & :global {
        & .modal__panel {
          max-width: unset;
          height: auto;
          padding: 0;
        }
      }
    }
  }
}

@media (--screen-xl) {
  .root {
    & :global {
      & .modal__panel {
        max-width: 564px;
        padding: 48px 40px;
      }
    }
  }
}
