@import '../../../../styles/core.css';

.seoSwitcher {
  display: none;

  & + .seoDetailText {
    height: 0;
    overflow: hidden;
  }

  &:checked + .seoDetailText {
    height: auto;
  }

  & + .seoDetailText + .readMoreLink {
    & .readMore {
      display: inline;
    }

    & .readLess {
      display: none;
    }
  }

  &:checked + .seoDetailText + .readMoreLink {
    & .readMore {
      display: none;
    }

    & .readLess {
      display: inline;
    }
  }
}

.servicesSwitcher {
  display: none;

  & + .mainFeaturesItems .mainFeaturesItem {
    @media (--tablet) {
      display: none;

      &:nth-child(-n+4) {
        display: flex;
      }
    }
  }

  &:checked + .mainFeaturesItems .mainFeaturesItem {
    @media (--tablet) {
      display: flex;
    }
  }

  & + .mainFeaturesItems .mainFeaturesExpand {
    & .readMore {
      display: inline;
    }

    & .readLess {
      display: none;
    }
  }

  &:checked + .mainFeaturesItems .mainFeaturesExpand {
    & .readMore {
      display: none;
    }

    & .readLess {
      display: inline;
    }

    & .mainFeaturesExpandIcon {
      transform: rotate(180deg);
    }
  }
}

.review {
  min-width: calc(50%);

  @media (max-width: 768px) {
    min-width: calc(100%);
  }
}

.main {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 40px 0;

  @media (--tablet) {
    padding: 16px 0 40px 0;
  }

  & .mainPart {
    display: block;
    width: 100%;
    max-width: 1538px;
    box-sizing: border-box;

    &:first-of-type {
      margin-top: 120px;

      @media (--desktopSmall) {
        margin-top: 80px;
      }

      @media (--tabletLandscape) {
        margin-top: 60px;
      }

      @media (--tabletLandscape) {
        margin-top: 40px;
      }

      @media (--tablet) {
        margin-top: 16px;
      }
    }
  }

  & .mainFeatures {
    display: flex;
    position: relative;
    padding-right: 370px;

    @media (--desktopLarge) {
      padding-right: calc(75px + 370px);
    }

    @media (--desktop) {
      padding-right: calc(48px + 370px);
    }

    @media (--desktopSmall) {
      padding-right: 40px;
    }

    @media (--tablet) {
      padding-right: 15px;
    }


    @media (--desktopSmall) {
      height: auto;
      flex-direction: column;
      position: static;
    }

    & .mainFeaturesSlider {
      width: 100%;
      min-height: 896px !important;

      @media (--desktopLarge) {
        min-height: 690px !important;
      }

      @media (width < 1538px) {
        min-height: calc((100vw - (150px + 370px)) * .75) !important;
      }

      @media (--desktop) {
        min-height: calc((100vw - (96px + 370px)) * .75) !important;
      }

      @media (width < 1370px) {
        min-height: 630px !important;
      }

      @media (--desktopSmall) {
        min-height: calc((100vw - 80px) * .75) !important;
      }

      @media (--tablet) {
        margin: 0 -16px;
        width: calc(100% + 32px);
        min-height: 75vw !important;
      }

      & :global(.slider-frame) {
        @media (max-width: 767px) {
          overflow: visible !important;
        }
      }

      & :global(.slider-slide) {
        min-height: 240px;
        width: 100% !important;
        height: 100%;
      }

      & a.mainFeaturesSliderItem {
        min-height: auto;
        display: block;
      }

      & .mainFeaturesSliderItem {
        position: relative;
        overflow: hidden;
        width: calc(1538px - 150px - 370px);
        min-width: calc(1538px - 150px - 370px);
        height: calc((1538px - 150px - 370px) * 3 / 4);
        max-height: calc((1538px - 150px - 370px) * 3 / 4);
        aspect-ratio: 4 / 3;

        @media (--desktopLarge) {
          width: calc(1538px - 150px - 370px);
          min-width: calc(1538px - 150px - 370px);
          height: calc((1538px - 150px - 370px) * 3 / 4);
          max-height: calc((1538px - 150px - 370px) * 3 / 4);
        }

        @media (width < 1538px) {
          width: calc(100vw - 150px - 370px);
          min-width: calc(100vw - 150px - 370px);
          height: calc((100vw - 150px - 370px) * 3 / 4);
          max-height: calc((100vw - 150px - 370px) * 3 / 4);
        }

        @media (--desktop) {
          min-width: calc(100vw - 96px - 370px);
          width: calc(100vw - 96px - 370px);
          height: calc((100vw - 96px - 370px) * 3 / 4);
          max-height: calc((100vw - 96px - 370px) * 3 / 4);
        }

        @media (--desktopSmall) {
          min-width: calc(100vw - 80px);
          width: calc(100vw - 80px);
          height: calc((100vw - 80px) * 3 / 4);
          max-height: calc((100vw - 80px) * 3 / 4);
        }

        @media (--tablet) {
          width: calc(100vw);
          min-width: calc(100vw);
          height: calc((100vw) * 3 / 4);
          max-height: calc((100vw) * 3 / 4);
        }

        & picture {
          height: 100%;
        }

        & .mainFeaturesSliderItemButton {
          position: absolute;
          top: calc(100% - 112px);
          left: 80px;

          & svg {
            margin: 0 0 0 15px;
            stroke: var(--colorWhite);
          }
        }

        & .mainFeaturesSliderItemInfo {
          position: absolute;
          top: 64px;
          left: 80px;
          display: flex;
          flex-direction: column;

          & .mainFeaturesSliderItemLabel {
            padding: 10px;
            text-align: left;
            width: max-content;
            background-color: var(--colorBackgroundAccent);
          }

          & .mainFeaturesSliderItemTitle {
            padding: 30px;
            text-align: left;
            background: rgba(247, 246, 245, 0.9);

            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 43px;
          }
        }

        & img {
          width: 100%;
          display: block;
          object-fit: cover;
          height: 100%;
        }

        & p {
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    & .mainFeaturesDetails {
      width: 370px;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      flex-shrink: 0;
      padding: 0 0 24px 24px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;

      @media (--desktopSmall) {
        margin-top: 80px;
        padding: 0;
        width: 100%;
        position: static;
      }

      @media (--tablet) {
        margin: 12px 0 0 0;
      }

      right: 0;

      @media (--desktopLarge) {
        right: 75px;
      }

      @media (--desktop) {
        right: 48px;
      }

      @media (--desktopSmall) {
        right: 0;
      }
    }

    & .mainFeaturesTitle {
      margin: 0 0 15px 0;

      @media (--tablet) {
        margin: 0 0 4px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        font-family: 'Playfair Display', serif
      }
    }

    & .mainFeaturesItems {
      height: 100%;
      margin: -10px -10px 0 -10px;
      list-style: none;
      display: flex;
      flex-direction: column;

      @media (--desktopSmall) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: calc(100% + 20px);
      }

      @media (--tablet) {
        margin: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
      }

      & > * {
        width: calc(100% - 20px);
        flex-grow: 1;
        margin: 10px 10px 0 10px;

        @media (--desktopSmall) {
          width: calc(50% - 40px);
        }

        @media (--tablet) {
          margin: 12px 0 0 0;
          width: 100%;
        }
      }
    }

    & .mainFeaturesExpand {
      outline: none;
      border: none;
      display: none;
      align-items: center;
      background-color: transparent;
      color: #202E95;
      font-family: Manrope, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;

      @media (--tablet) {
        display: flex;
      }

      .mainFeaturesExpandIcon {
        margin: 0 0 0 4px;
        color: #202E95;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        & img {
          width: 28px;
          height: 28px;
          color: #202E95;
          display: block;
        }

        & svg {
          color: #202E95;
          display: block;
        }
      }
    }

    & .mainFeaturesItem {
      padding: 8px 20px 8px 24px;
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      color: var(--colorTextPrimary);
      background-color: #E2D9CA;
      cursor: pointer;
      flex-shrink: 0;
      flex-grow: 1;
      box-sizing: border-box;

      @media (--tablet) {
        padding: 8px 48px 8px 12px;
        min-height: 44px;
        align-items: center;
        display: none;
      }

      &:hover {
        background-color: var(--colorBackgroundHover);
        color: var(--colorPrimary);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);

        & .mainFeaturesItemTitle * {
          color: var(--colorPrimary) !important;
        }

        & .mainFeaturesItemDescription * {
          color: var(--colorPrimary) !important;
        }

        &:after {
          content: '';
          width: 19px;
          height: 11px;
          position: absolute;
          top: calc(50% - 5px);
          right: 15px;
          background: url('/static/imgs/main/arrow-link.svg') center no-repeat;

          @media (--tablet) {
            background: none;
            display: none;
          }
        }
      }

      .mainFeaturesItemActionIcon {
        display: none;
        width: 28px;
        height: 28px;
        position: absolute;
        top: 8px;
        right: 12px;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media (--tablet) {
          display: flex;
        }

        & img {
          width: 16px;
          height: 16px;
        }
      }

      & .mainFeaturesItemMedia {
        width: 24px;
        height: 100%;
        justify-content: center;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        align-items: center;

        @media (--tablet) {
          display: none;
        }
      }

      & .mainFeaturesItemIcon {
        width: 24px;
      }

      & .mainFeaturesItemHeader {
        padding: 0 0 0 22px;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;

        @media (--tablet) {
          padding: 0;
        }
      }

      & .mainFeaturesItemTitle {
        margin: 0;

        @media (--tablet) {
          font-family: Manrope, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
        }
      }

      & .mainFeaturesItemDescription {
        margin: 4px 0 0;

        & p {
          @media (--tablet) {
            font-family: Manrope, sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
          }
        }
      }
    }
  }

  & .mainSquareAction {
    width: calc(25% - 20px);
    height: auto;
    padding: 0;
    margin: 10px;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    @media (--desktopSmall) {
      width: calc(33.3% - 20px);
      margin: 10px;
    }

    @media (--tabletLandscape) {
      width: calc(50% - 20px);
      margin: 10px;
    }

    @media (--tablet) {
      display: none;
    }

    &.carpet {
      margin: 0 10px;

      @media (--tabletLandscape) {
        display: none;
      }
    }

    & .mainSquareActionContent {
      width: 230px;
      height: 230px;
      background-color: rgba(25, 30, 128, 0.9);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 70px 2px 30px 2px;
      border: 1px solid rgba(255, 255, 255, 0.9);
      outline: 5px solid rgba(25, 30, 128, 0.9);
      box-sizing: border-box;

      @media (--tablet) {
        padding: 70px 2px 30px 2px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
      }

      & .mainSquareActionTitle {
        font-family: 'Playfair Display', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 34px;
        white-space: pre-wrap;
        text-align: center;
        color: var(--colorWhite);
      }

      & .mainSquareActionButton {
        margin: 50px 0 0 0;
        position: relative;
        color: var(--colorWhite);
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: auto;
        min-width: auto;
        background: none;
        border: none;

        &:hover {
          background: none;
        }

        & svg {
          margin: 0 0 0 15px;
          stroke: var(--colorWhite);
        }
      }
    }

    &:hover {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);

      & .mainSquareActionContent {
        background-color: rgba(31, 37, 168, 0.9);
        outline: 5px solid rgba(31, 37, 168, 0.9);
      }
    }

  }

  & .mainCategories {
    display: flex;

    @media (--tabletLandscape) {
      flex-wrap: wrap;
    }

    &.mainCarpetCategories {
      @media (--tablet) {
        display: flex;
      }
    }

    &.mainLibertyCategories {
      @media (--tablet) {
        display: none;
      }
    }

    & .mainSquareAction {
      @media (--tablet) {
        display: none;
      }
    }

    & .mainCategoriesHeader {
      display: flex;
      flex-shrink: 0;
    }

    & .mainCategoriesItems {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px -20px;
      padding: 0 0 0 10px;
      width: calc(100% + 20px);
      list-style: none;
      box-sizing: border-box;

      & .mainCategoriesItem {
        width: calc(33.3% - 20px);
        height: 160px;
        margin: 0 10px 20px 10px;
        padding: 30px 45px 24px 30px;
        flex-shrink: 0;
        background-color: var(--colorBackgroundNeutral);
        background-size: contain;
        background-position: center right;
        background-repeat: no-repeat;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;

        & > * {
          position: static;
        }

        &.sale {
          background-color: #D5C4A7;
        }

        &:hover {
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
          color: var(--colorHover);
          background-color: var(--colorBackgroundHover);
        }

        @media (--desktopSmall) {
          width: calc(50% - 20px);
        }

        @media (--tabletLandscape) {
          width: calc(100% + 20px);
          width: calc(50% + 20px);
        }

        @media (--tablet) {
          height: 78px;
          padding: 20px 8px;
          margin: 0 6px 14px 6px;
        }

        @media (--phone) {
          margin: 0 4px 14px 4px;
        }

        & .mainCategoriesItemHeader {
          display: flex;
          flex-direction: column;
          height: 100%;
          align-items: flex-start;
          justify-content: center;
          position: static;

          & .mainCategoriesItemPicture {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            aspect-ratio: 1/1;

            & .mainCategoriesItemImage {
              object-fit: cover;
              height: 100%;
              display: block;
            }
          }
        }

        & .mainCategoriesItemTitle h5 {
          font-family: 'Playfair Display', serif;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          line-height: 29px;

          @media (--tablet) {
            font-size: 14px;
            line-height: 17px;
          }
        }

        & .mainCategoriesItemIcon {
          display: none;
        }

        &:hover {
          background-color: var(--colorWhite);

          & .mainCategoriesItemTitle {
            color: var(--colorPrimary);
          }

          & .mainCategoriesItemIcon {
            display: block;
            position: absolute;
            bottom: 14px;
            width: 20px;
            height: 13px;

            @media (--tablet) {
              display: none;
            }
          }
        }
      }

      @media (--tabletLandscape) {
        width: calc(50% - 10px);
        padding: 0;
        flex-wrap: wrap;
        width: calc(100% + 20px);

        & .mainCategoriesItem {
          width: calc(50% - 20px);
          margin: 0 10px 14px 10px;
        }

        & + .mainCategoriesItems {
          padding: 0;
          width: calc(100% + 20px);
          margin: 20px -10px 0 -10px;

          & .mainCategoriesItem {
            width: calc(50% - 20px);
          }
        }
      }

      @media (--tablet) {
        padding: 0;
        flex-wrap: wrap;
        width: calc(100% + 12px);
        margin: 0 -6px 14px -6px;

        & .mainCategoriesItem {
          width: calc(50% - 12px);
          margin: 0 6px 14px 6px;
        }
      }

      @media (--phone) {
        width: calc(100% + 8px);
        margin: 0 -4px 14px -4px;

        & .mainCategoriesItem {
          width: calc(50% - 8px);
          margin: 0 4px 14px 4px;
        }
      }
    }

    & .mainCategoriesBigItems {
      display: flex;
      flex-wrap: wrap;
      margin: -10px;
      padding: 0;
      width: calc(100% + 20px);
      list-style: none;
      flex-direction: row-reverse;

      @media (--desktopSmall) {
        flex-direction: row;
      }

      & .mainCategoriesBigItem {
        width: calc(25% - 20px);
        height: 340px;
        margin: 10px;
        flex-shrink: 0;
        flex-grow: 1;
        background-color: var(--colorBackgroundNeutral);
        background-size: contain;
        background-position: bottom center;
        background-repeat: no-repeat;
        cursor: pointer;
        box-sizing: border-box;

        padding: 70px 20px 0 20px;

        & > * {
          position: static;
        }

        @media (--desktopSmall) {
          width: calc(50% - 20px);
        }

        @media (--tabletLandscape) {
          width: calc(50% - 20px);
        }

        @media (--tablet) {
          min-height: 180px;
          height: auto;
          padding: 20px 5px 0 5px;
        }

        &:hover {
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
          color: var(--colorHover);
          background-color: var(--colorBackgroundHover);

          & .mainCategoriesBigItemTitle {
            color: var(--colorHover);
          }
        }

        & .mainCategoriesBigItemHeader {
          width: 100%;
          position: static;

          & .mainCategoriesBigItemPicture {
            position: absolute;
            bottom: 0;
            right: 0;

            & .mainCategoriesBigItemImage {
              object-fit: cover;
              width: 100%;
              display: block;
            }
          }
        }

        & .mainCategoriesBigItemTitle {
          text-align: center;
          width: 100%;
          font-family: 'Playfair Display', serif;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          line-height: 29px;

          @media (--tablet) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }

  & .mainFeatureBanners {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -10px;

    @media (--tabletLandscape) {
      flex-direction: column;
    }

    & .mainFeatureBannersItem {
      margin: 10px;
      width: calc(50% - 20px);
      height: 270px;
      flex-shrink: 0;
      background-color: var(--colorBackgroundPrimary);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right center;
      overflow: hidden;

      & picture {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 1px;
        right: -1px;

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: right;
        }
      }

      @media (--tabletLandscape) {
        width: calc(100% - 20px);
        background-position: right center;
      }

      @media (--desktopSmall) {
        height: 220px;
      }

      @media (--tablet) {
        height: 112px;
        border: 1px solid var(--colorPrimary)
      }

      &.mainFeatureBannersItemHowSelect {
        & .mainFeatureBannersItemContent {
          box-sizing: border-box;
          padding-right: 60%;

          @media (--desktop) {
            padding-right: 60%;
          }

          @media (--tabletLandscape) {
            padding-right: 40%;
          }

          @media (--tablet) {
            padding-right: 50%;
          }
        }
      }

      &.mainFeatureBannersItemFreeFit {
        & .mainFeatureBannersItemContent {
          padding-right: 50%;
        }
      }

      & .mainFeatureBannersItemContent {
        padding: 40px 40% 40px 45px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        box-sizing: border-box;

        @media (--tablet) {
          padding: 10px 15px;
        }
      }

      & .mainFeatureBannersItemTitle {
        font-family: 'Playfair Display', serif;
        font-style: normal;
        font-weight: bold;

        @media (--tablet) {
          color: var(--colorPrimary);
        }
      }

      & .mainFeatureBannersItemDescription {

      }

      & .mainFeatureBannersItemButton {
        @media (--tablet) {
          display: none;
        }
      }

      &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);

        & .mainFeatureBannersItemTitle {
          color: var(--colorHover);
        }
      }
    }
  }

  & .mainMeasures {
    display: flex;
    align-items: center;
    margin: 0 -10px 0 -10px;
    flex-wrap: wrap;

    & .mainMeasure {
      position: relative;
      margin: 10px;
      width: calc(50% - 20px);
      height: 164px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid #d5d5d5;
      background-color: var(--colorWhite);
      padding: 20px;
      display: flex;
      align-items: center;

      @media (--tabletLandscape) {
        height: 180px;
      }

      @media (--tabletBig) {
        width: calc(100% - 20px);
        height: auto;
      }
    }

    & .mainMeasureContent {
      display: flex;
      height: 100%;
      align-items: flex-start;
    }

    & .mainMeasureTitle {
      display: flex;
      width: 104px;
      flex-shrink: 0;
      flex-direction: column;
      align-items: center;
      font-family: 'Playfair Display', serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      color: var(--colorPrimary);

      &.mainMeasureTitleRow {
        flex-direction: row;
        align-items: flex-start;
      }

      & strong {
        font-style: normal;
        font-weight: bold;
        font-size: 64px;
        line-height: 64px;
      }
    }

    & .mainMeasureDescription {
      margin: 0 0 0 20px;
      color: var(--colorTextSecondary);
      font-size: 16px !important;
    }

    & .mainMeasureGeneral {
      min-height: 156px;
      align-items: flex-start;
      background-color: var(--colorBackgroundAccent);

      & .mainMeasureDescription {
        color: var(--colorTextPrimary);
        margin: 0 80px 0 0;
        font-family: 'Playfair Display', serif;
        z-index: 1;

        @media (--tabletLandscape) {
          margin-right: 90px;
        }

        & a {
          text-decoration: underline;
          font-weight: 700;
          cursor: pointer;
          color: var(--colorTextPrimary);

          &:hover {
            color: var(--colorHover);
          }
        }
      }

      & img {
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      }
    }

  }

  & .mainShops {
    & .mainShopsButton {
      @media (--tablet) {
        display: none;
      }
    }

    & .mainShopsButtonSecond {
      display: none;

      margin: 20px 0 0 0;

      @media (--tablet) {
        display: flex;
      }
    }

    & .mainShopsHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & svg {
        margin: 0 0 0 15px;
        stroke: var(--colorWhite);
      }
    }

    & .mainShopsSlider {
      width: 100%;
      margin: 50px 0 0 0;

      @media (--desktopSmall) {
        margin: 30px 0 0 0;
      }
    }

    & .mainShop {
      padding: 0 12px 0 12px;
      width: 100%;
      min-width: 50%;
      height: 100%;
      flex-grow: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      box-sizing: border-box;

      @media (--desktopSmall) {
        min-width: 100%;
      }

      & a {
        display: block;
        width: 100%;
      }

      & .mainShopImage {
        object-fit: cover;
        width: 100%;
        height: 400px;

        @media (--desktopSmall) {
          height: 360px;
        }
      }

      & .mainShopTitle {
        min-height: 68px;
        margin: 30px 0 0 0;
      }

      &:hover {
        & .mainShopTitle {
          color: var(--colorHover);
        }
      }

      & .mainShopInfo {
        padding: 20px 0;
        margin: 30px 0 0 0;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        flex-grow: 1;
        align-items: flex-start;
        width: 100%;
        background-color: var(--colorBackgroundPrimary);

        & .mainShopInfoItem {
          display: flex;
          align-items: flex-start;
          padding: 0 20px 0 20px;
          flex-shrink: 0;
          width: 100%;
          height: auto;

          & + .mainShopInfoItem {
            margin-top: 16px;
            border-left: none;
          }

          & .mainShopInfoItemMedia {
            width: 40px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
          }

          & .mainShopInfoItemIcon {
            top: 3px;
            flex-shrink: 0;
          }

          & .mainShopInfoItemTitle {
            margin: 0 0 0 8px;
            color: var(--colorTextSecondary);
          }
        }
      }
    }
  }

  & .mainDesignCases {
    background-color: var(--colorBackgroundPrimary);
    padding: 100px 0;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & .mainDesignCasesContent {
      width: 100%;
      max-width: 1538px;
      flex-shrink: 0;
      box-sizing: border-box;

      @media (--desktopLarge) {
        padding: 0 75px;
      }

      @media (--desktop) {
        padding: 0 48px;
      }

      @media (--desktopSmall) {
        padding: 0 40px;
      }

      @media (--tablet) {
        padding: 0 15px;
      }
    }

    & .mainDesignCasesHeader {
      display: flex;
      align-items: center;
    }

    & .mainDesignCasesItems {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: 50px -12px 0 -12px;
      list-style: none;

      @media (--desktopSmall) {
        margin: 30px 0 0 0;
      }
    }

    & .mainDesignCase {
      width: calc(50% - 24px);
      margin: 12px 12px;
      flex-shrink: 0;

      @media (--tabletLandscape) {
        width: calc(100% - 24px);
      }

      & .mainDesignCaseSlider {
      }

      .mainDesignCasePicture {
        min-width: 100%;
      }

      & .mainDesignCaseImage {
        height: 400px;
        object-fit: cover;
        min-width: 100%;

        @media (--desktopSmall) {
          height: 360px;
        }

        @media (--tabletLandscape) {
          min-width: 100%;
        }
        @media (--tablet) {
          height: 200px;
        }
      }

      & .mainDesignCaseDescription {
        margin: 30px 0 0 0;
        color: var(--colorTextLight);
      }

      & .mainDesignCaseLink {
        margin: 4px 0 0 0;
        cursor: pointer;
      }

      & .mainDesignCaseTitle {
        color: var(--colorPrimary);

        &:hover {
          color: var(--colorPrimary);
          text-decoration: underline;
        }
      }


      &:hover {
        & .mainDesignCaseTitle {
          color: var(--colorHover);
          text-decoration: none;
        }
      }
    }
  }

  & .mainDesigners {
    & .mainDesignersHeader {
      width: 100%;
    }

    & .mainDesignersContent {
      display: flex;
      align-items: flex-start;
      margin: 50px 0 0 0;
      width: 100%;
      flex-direction: column;

      @media (--desktopSmall) {
        margin: 30px 0 0 0;
      }
    }

    & .mainDesignersMedia {
      width: 100%;
      padding: 0;

      @media (--tabletLandscape) {
        padding: 0;
        width: 100%;
      }
    }

    & .mainDesignersImage {
      width: 100%;
      object-fit: cover;
      max-height: 454px;
    }

    & .mainDesignersInfo {
      width: 100%;
      display: flex;
      padding: 60px 0 0 0;

      @media (--tabletLandscape) {
        margin: 24px 0 0 0;
        padding: 0;
        flex-direction: column;
      }
    }

    & .mainDesignersInfoColumn {
      width: 50%;
      padding: 0 40px 0 0;
      box-sizing: border-box;

      & + .mainDesignersInfoColumn {
        padding: 0 0 0 40px;
        border-left: 1px solid var(--colorBorderSecondary);

        @media (--tabletLandscape) {
          border-left: none;
          padding: 0;
        }
      }

      @media (--tabletLandscape) {
        width: 100%;
      }
    }

    & .mainDesignersContentTitle {
      width: 100%;
      font-weight: 700;
    }

    & .mainDesignersItems {
      display: flex;
      flex-direction: column;
      list-style: none;

      & .mainDesignersItem {
        display: flex;
        align-items: flex-start;
        margin: 0 0 35px 0;

        & .mainDesignersItemMedia {
          margin: 0 20px 0 0;
          top: 2px;
        }

        & .mainDesignersItemTitle {
        }
      }
    }
  }

  & .mainWholesale {
    & .mainWholesaleContent {
      height: 635px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 100px;
      background-blend-mode: darken;
      background: url('/static/imgs/main/wholesale.webp') rgba(0, 0, 0, 0.64) center no-repeat;
      background-size: cover;
      box-sizing: border-box;

      @media (--tablet) {
        height: 240px;
        padding: 20px;
      }
    }

    & .mainWholesaleTitle {
      color: var(--colorWhite);
    }

    & .mainWholesaleButton {
      width: 240px;
      display: flex;
      align-items: center;
      justify-content: center;

      & svg {
        margin: 0 0 0 8px;
        stroke: #ffffff;
      }
    }
  }

  & .mainBlog {
    & .mainBlogHeader {
      margin: 0 0 50px 0;

      @media (--desktopSmall) {
        margin: 0 0 30px 0;
      }
    }

    & .mainBlogContent {
      display: flex;

      @media screen and (--tabletLandscape) {
        flex-direction: column;
      }
    }

    & .mainBlogItems {
      height: auto;
      width: 30%;
      list-style: none;
      padding: 0px 0px 0px 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (--tabletLandscape) {
        padding: 0;
        margin: 20px 0 0 0;
        width: 100%;
      }
    }

    & .mainBlogItem {
      width: 100%;
      height: calc(33.3% - 10px);
      max-height: calc(33.3% - 10px);

      @media screen and (--tabletLandscape) {
        height: 200px;

        & + .mainBlogItem {
          margin: 20px 0 0 0;
        }
      }

      & .mainBlogItemLink {
        height: 100%;
        display: block;
        background-position: center;
        background-size: cover;
        background-blend-mode: darken;
        background-color: rgba(0, 0, 0, 0.54);

        & .mainBlogItemLinkPicture {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.54);
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        & .mainBlogItemLinkImg {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    & .mainBlogItemBig {
      width: 70%;
      height: 540px;
      flex-shrink: 0;
      background-position: center;
      background-size: cover;
      background-blend-mode: darken;
      background-color: rgba(0, 0, 0, 0.54);

      & .mainBlogItemBigPicture {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.54);
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      & .mainBlogItemBigImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media screen and (--desktopSmall) {
        height: 480px;
      }

      @media screen and (--tabletLandscape) {
        width: 100%;
        height: 320px;
      }

      & .mainBlogItemBigTitle {
        font-size: 39px;
        line-height: 48px;
        @media screen and (--tabletLandscape) {
          font-size: 18px;
          line-height: 24px;
          max-width: 95%;
        }
      }

      & .mainBlogItemBigCategory {
        left: 30px;
        top: 45px;
        font-size: 12px;

        @media screen and (--tabletLandscape) {
          top: 20px;
          left: 10px;
        }
      }
    }

    & .mainBlogItemCategory {
      position: absolute;
      top: 20px;
      left: 30px;
      font-size: 12px;
      font-weight: 500;
      color: var(--colorWhite);
      padding: 4px 10px;
      border: 1px solid rgba(255, 255, 255, 0.6);
      cursor: pointer;
      white-space: nowrap;
      box-sizing: border-box;

      @media screen and (--tabletLandscape) {
        top: 20px;
        left: 10px;
      }

      &:hover {
        color: var(--colorHover);
        border: 1px solid var(--colorHover);
      }

    }

    & .mainBlogItemTitle {
      position: absolute;
      bottom: 20px;
      left: 30px;

      text-align: left;
      cursor: pointer;
      display: block;
      font-family: Manrope, system-ui, sans-serif;
      color: var(--colorWhite);
      width: calc(100% - 40px);

      @media screen and (--tabletLandscape) {
        left: 10px;
        width: calc(100% - 20px);
      }

      &:hover {
        color: var(--colorHover);
      }

      @media screen and (--tabletLandscape) {
        font-size: 18px;
        line-height: 24px;
        max-width: 95%;
      }

    }

    & .mainBlogItemBigReadMore {
      width: 200px;
      margin-top: 20px;

      & svg {
        margin: 0 0 0 8px;
        stroke: #ffffff;
      }

      @media screen and (--tabletLandscape) {
        display: none;
      }
    }
  }

  & .mainReviews {
    width: 100%;
    background-color: #F7F6F5;
    padding: 40px 30px;
    box-sizing: border-box;

    & .mainReviewsActions {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    & .mainReviewsSlider {
    }

    & .mainReview {
      width: calc(100% - 24px);
      padding: 40px 64px 40px 40px;
      margin: 0 12px;
      display: flex;
      align-items: flex-start;
      box-sizing: border-box;

      @media (--tabletLandscape) {
        flex-direction: column;
        padding: 40px 64px;
      }

      @media (--tablet) {
        padding: 20px 32px;
      }
    }

    & .mainReviewMedia {
      width: 180px;
      min-height: 180px;
      height: 180px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 0;
      justify-content: flex-start;
      margin-right: 40px;

      @media (--tabletLandscape) {
        margin: 0 0 32px 0;
        width: 80px;
        min-height: 80px;
      }
    }

    & .mainReviewImage {
      object-fit: contain;
      width: 100%;
      max-height: 180px;
    }

    & .mainReviewText {
      margin-left: 20px;

      @media (--tabletLandscape) {
        margin: 24px 0 0 0;
      }
    }
  }

  & .mainDesignCollections {
    background-color: var(--colorBackgroundPrimary);
    padding: 100px 0;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    @media (--tablet) {
      padding: 30px 0;
    }

    & .mainDesignCollectionsContent {
      width: 100%;
      max-width: 1538px;
      flex-shrink: 0;
      box-sizing: border-box;

      @media (--desktopLarge) {
        padding: 0 75px 24px;
      }

      @media (--desktop) {
        padding: 0 48px 24px;
      }

      @media (--desktopSmall) {
        padding: 0 40px 24px;
      }

      @media (--tablet) {
        padding: 0 15px 24px;
      }
    }

    & .mainDesignCollectionsHeader {
      display: flex;
      align-items: center;
      margin-bottom: 50px;

      @media (--desktopSmall) {
        margin-bottom: 30px;
      }
    }
  }

  & .mainFilters {
    & .mainFiltersHeader {
      margin: 56px 0 0 0;
    }

    & .mainFiltersContent {
      margin: 50px 0 0 0;

      @media (--desktopSmall) {
        margin: 30px 0 0 0;
      }
    }

    & .mainFiltersTabsHeader {
      margin: 0 -5px;
      display: flex;
      width: 100%;
      background-color: var(--colorBackgroundSecondary);

      @media (--desktopSmall) {
        background-color: transparent;
        flex-wrap: wrap;
        margin: -10px;
        width: calc(100% + 20px);
      }

      & .mainFiltersTabsHeaderButton {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 5px;
        position: relative;
        margin: 0 0;
        cursor: pointer;
        box-sizing: border-box;

        background-color: var(--colorBackgroundSecondary);
        border: 1px solid var(--colorBorderDefault);
        outline: none;

        @media (--desktopSmall) {
          margin: 10px;
          width: calc(33.3% - 20px);
        }

        @media (--tabletLandscape) {
          width: calc(50% - 20px);
        }

        @media (--tablet) {
          width: calc(100% - 20px);
        }

        & + .mainFiltersTabsHeaderButton {
          margin-left: -1px;

          @media (--desktopSmall) {
            margin-left: 10px;
          }

        }

        & .icon {
          flex-shrink: 0;
          margin: 0 10px 0 0;
        }

        & .mainFiltersTabsHeaderButtonContent {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 30px 6px;
          border: 1px solid var(--colorBackgroundSecondary);
          background-color: var(--colorBackgroundSecondary);
          color: var(--colorPrimary);

          font-family: Manrope, system-ui, sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          white-space: nowrap;
          justify-content: center;
          box-sizing: border-box;

          @media (--desktopSmall) {
            padding: 18px;
          }

          @media (--tablet) {
            padding: 8px 15px;
            font-size: 14px;
            line-height: 20px;
          }

          & .mainFiltersTabsHeaderButtonContentArrow {
            display: none;
            margin: 0 0 0 10px;

            @media (--tablet) {
              display: inline-flex;
            }
          }
        }

        &:visited {
          background-color: var(--colorBackgroundSecondary);
          border: 1px solid var(--colorBorderDefault);
          color: var(--colorPrimary);

          & .mainFiltersTabsHeaderButtonContent {
            border: 1px solid var(--colorBackgroundSecondary);
            background-color: var(--colorBackgroundSecondary);
            color: var(--colorPrimary);
          }
        }

        &:hover {
          background-color: var(--colorHover);
          border: 1px solid var(--colorHover);
          color: var(--colorWhite);

          & .mainFiltersTabsHeaderButtonContent {
            background-color: var(--colorHover);
            border: 1px solid var(--colorHover);
            color: var(--colorWhite);
          }
        }

        &.selected {
          background-color: var(--colorPrimary);
          border: 1px solid var(--colorPrimary);
          color: var(--colorWhite);

          & .mainFiltersTabsHeaderButtonContent {
            background-color: var(--colorPrimary);
            border: 1px solid var(--colorWhite);
            color: var(--colorWhite);

            @media (--tablet) {
              border: 1px solid var(--colorPrimary);
            }
          }
        }
      }
    }

    & .mainFiltersTabsItems {
      margin: 20px 0 0 0;
      background-color: var(--colorBackgroundPrimary);
    }

    & .mainFiltersTabsItem {
      padding: 20px;
      align-items: flex-start;
      display: none;
      box-sizing: border-box;

      @media (--tablet) {
        margin: 10px;
        flex-direction: column;
        background-color: var(--colorBackgroundSecondary);

      }

      &.selected {
        display: flex;
      }

      & .mainFiltersTabsItemHeader {
        width: calc(100% - 450px);
        padding: 0 40px 0 0;
        flex-shrink: 0;
        margin: -16px 0;

        @media (--tablet) {
          width: 100%;
          padding: 0;
        }

      }

      & .mainFiltersTabsItemContent {
        width: 100%;
        display: flex;
        flex-direction: column;

        @media (--tablet) {
          margin: 20px 0 0 0;
        }

        & .mainFiltersTabsItemButton {
          margin: 40px 0 0 0;
          align-self: flex-end;

          @media (--tablet) {
            align-self: flex-start;
          }

          & svg {
            margin: 0 0 0 15px;
            stroke: var(--colorWhite);
          }
        }

        & .mainFiltersTabsItemColorFilter {
          display: flex;
          flex-wrap: wrap;
          margin: -5px;

          & .mainFiltersTabsItemColorFilterItem {
            width: 40px;
            height: 40px;
            margin: 5px;
            border: 3px solid var(--colorBorderDefault);
            outline: 3px solid transparent;
            cursor: pointer;
            background-size: 200% 200%;
            background-position: center;
            background-repeat: no-repeat;

            &:hover {
              outline: 3px solid var(--colorHover);
            }

            &.selected {
              outline: 3px solid var(--colorPrimary);
            }
          }
        }

        & .mainFiltersTabsItemFilter {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          margin: -5px;
          max-height: 240px;

          & .mainFiltersTabsItemFilterItem {
            margin: 5px;
            display: inline-flex;
          }
        }

      }
    }
  }

  & .readMoreLink {
    text-decoration: underline;
    color: inherit;
    cursor: pointer;

    &:hover {
      color: var(--colorHover);
    }
  }

  & .mainMobileFeatures {
    display: none;

    @media (--tablet) {
      display: block;
    }

    & .mainMobileFeaturesTitle {
      width: 100%;
      text-align: center;
    }

    & .mainMobileFeaturesBanner {
      margin-top: 11px;
    }

    & .mainMobileFeaturesActions {
      max-height: 200px;
      min-height: 180px;
      width: 100vw;
      padding: 15px 0;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin: 20px -15px 0 -15px;
      position: relative;
      box-sizing: border-box;

      & picture {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    & .mainMobileFeaturesAction {
      width: calc(50% - 20px);
      margin: 0 2px 0 15px;

      & + .mainMobileFeaturesAction {
        margin: 0 15px 0 2px;
      }

      & svg {
        flex-shrink: 0;
        margin: 0 0 0 8px;
        stroke: var(--colorWhite);
      }

      &:hover {
        color: var(--colorWhite);

        & svg {
          stroke: var(--colorWhite);
        }

      }

      &.white {
        background-color: var(--colorWhite);

        & svg {
          stroke: var(--colorPrimary);
        }

        &:hover {
          color: var(--colorHover);

          & svg {
            stroke: var(--colorHover);
          }

        }
      }
    }
  }
}

.quickLinks {
  width: calc(100% + 30px);
  height: 60px;
  margin: 16px -15px 0 -15px;
  overflow: hidden;
  display: none;
  position: relative;

  @media (--tablet) {
    display: block;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100px;
    height: 60px;
    right: 0;
    top: 0;
    background: linear-gradient(90deg, rgba(247, 246, 245, 0.4) 47.5%, #979797 83.59%);
    pointer-events: none;
    opacity: 0.5;
  }

  & .quickLinksItems {
    display: flex;
    margin: 0 -3px;
    width: calc(100% + 6px);
    padding: 0;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    overflow-style: none;
    overflow-scrolling: touch;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &::after {
      content: '';
      flex: 0 0 15px;
    }

    &::before {
      content: '';
      flex: 0 0 15px;
    }
  }

  & .quickLinksItem {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
    width: 100px;
    height: 60px;
    padding: 0 12px;
    background: #F8F8F9;
    margin: 0 3px;
    box-sizing: border-box;

    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    color: var(--colorTextAccent);
  }
}



.mainSection {
  box-sizing: border-box;
  width: 100%;
  max-width: 1538px;
  padding: 0;

  @media (--desktopLarge) {
    padding: 0 75px;
  }

  @media (--desktop) {
    padding: 0 48px;
  }

  @media (--desktopSmall) {
    padding: 0 40px;
  }

  @media (--tablet) {
    padding: 0 16px;
  }

  &:not(.mainMobileFeatures) + .mainSection {
    margin-top: 120px;

    @media (--desktopSmall) {
      margin-top: 80px;
    }

    @media (--tabletLandscape) {
      margin-top: 60px;
    }

    @media (--tabletLandscape) {
      margin-top: 40px;
    }

    @media (--tablet) {
      margin-top: 16px;
    }

    &:empty {
      margin-top: 0;
    }
  }

  &.mainMobileFeatures + .mainSection {
    margin-top: 0;

    @media (--tablet) {
      margin-top: 16px;
    }

    &:empty {
      margin-top: 0;
    }
  }
}