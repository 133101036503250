@import '../../../../shared/styles/core.css';

.root {
  position: absolute;
  z-index: 50;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: initial;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  box-sizing: border-box;
  width: 100%;
  min-width: 48px;
  height: 100%;
  min-height: 48px;
  margin: 0;
  padding: 0;
  background-color: inherit;
  letter-spacing: -0.12px;
  transition: initial;

  & .icon {
    width: 28px;
    height: 28px;
  }

  .desktopText {
    display: none;

    @media (--screen-lg) {
      display: inline;
    }
  }

  .mobileText {
    display: inline;
  }

  & :global {
    .tooltip__button {
      display: flex;
      gap: 4px;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-width: 48px;
      height: 100%;
      min-height: 48px;
      color: var(--default-grey-100, #212121);
    }
  }

  &.circle {
    padding: 0;
    border-radius: 50%;

    & :global {
      .tooltip__button {
        padding: 0;
        color: var(--default-white-100, #ffffff);
      }
    }
  }
}

.popoverPanel {
  width: 120px;
  padding: 8px;
  border-radius: 4px;
}

.topLeft {
  top: 16px;
  right: unset;
  bottom: unset;
  left: 16px;
}

.topRight {
  top: 16px;
  right: 16px;
  bottom: unset;
  left: unset;
}

.bottomLeft {
  top: unset;
  right: unset;
  bottom: 16px;
  left: 16px;
}

.bottomRight {
  top: unset;
  right: 16px;
  bottom: 16px;
  left: unset;
}

@media (--screen-md) {
  .item {
    cursor: initial;
  }
}

@media (--screen-lg) {
  .withTooltip {
    cursor: pointer;
  }

  .label {
    min-width: 44px;
    min-height: 44px;
    padding: 0 12px;

    & :global {
      .tooltip__button {
        min-width: 44px;
        min-height: 44px;
      }
    }
  }
}

@media (--screen-xl) {
  .label {
    min-width: 48px;
    min-height: 48px;

    & :global {
      .tooltip__button {
        min-width: 48px;
        min-height: 48px;
      }
    }
  }
}
