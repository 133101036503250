@import '../../../../styles/core.css';

.mainFeaturesSlider {
  width: 100%;
  min-height: 896px !important;
  gap: 0!important;

  @media (--desktopLarge) {
    min-height: 690px !important;
  }

  @media (width < 1538px) {
    min-height: calc((100vw - (150px + 370px)) * .75) !important;
  }

  @media (--desktop) {
    min-height: calc((100vw - (96px + 370px)) * .75) !important;
  }

  @media (width < 1370px) {
    min-height: 630px !important;
  }

  @media (--desktopSmall) {
    min-height: calc((100vw - 80px) * .75) !important;
  }

  @media (--tablet) {
    margin: 0;
    width: 100%;
    min-height: 75vw !important;
    gap: 8px!important;
    align-items: center;
  }

  & :global(.slider-frame) {
    @media (max-width: 767px) {
      overflow: visible !important;
    }
  }

  & :global(.slider-slide) {
    min-height: 240px;
    width: 100% !important;
    height: 100%;
  }

  :global {
    .swiper-pagination {
      display: flex;
      position: absolute;
      right: 40px;
      bottom: 24px;
      flex-wrap: wrap;
      gap: 8px;
      justify-content: center;
      align-items: center;
      z-index: 2;

      @media (--desktop) {
        bottom: 16px;
      }

      @media (--tabletLandscape) {
        bottom: 8px;
      }

      @media (--tablet) {
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }

    .swiper-pagination-bullet {
      position: relative;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: var(--default-grey-30);
      outline: 2px solid white;
    }

    .swiper-pagination-bullet-active {
      background-color: var(--priority-primary-80);
    }

    .swiper-zoom-container {
      position: relative;
    }

    .swiper-button-prev {
      position: absolute;
      top: calc(50% - 12px);
      left: 0;
      width: 48px;
      height: 48px;
      z-index: 2;
      background: rgba(248, 248, 248, 0.5);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;


      @media (--tablet) {
        width: 32px;
        height: 32px;
      }

      &:after {
        content: '<';
        color: var(--priority-primary-80);
        font-size: 32px;

        @media (--tablet) {
          font-size: 24px;
        }
      }
    }

    .swiper-button-next {
      position: absolute;
      top: calc(50% - 12px);
      right: 0;
      width: 48px;
      height: 48px;
      z-index: 2;
      background: rgba(248, 248, 248, 0.5);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;


      @media (--tablet) {
        width: 32px;
        height: 32px;
      }

      &:after {
        content: '>';
        color: var(--priority-primary-80);
        font-size: 32px;

        @media (--tablet) {
          font-size: 24px;
        }
      }
    }
  }

  & a.mainFeaturesSliderItem {
    min-height: auto;
    display: block;
  }

  & .mainFeaturesSliderItem {
    position: relative;
    width: calc(1538px - 370px);
    min-width: calc(1538px - 370px);
    height: calc((1538px - 370px) * 3 / 4);
    max-height: calc((1538px - 370px) * 3 / 4);
    aspect-ratio: 4 / 3;

    @media (--desktopLarge) {
      width: calc(1538px - 150px - 370px);
      min-width: calc(1538px - 150px - 370px);
      height: calc((1538px - 150px - 370px) * 3 / 4);
      max-height: calc((1538px - 150px - 370px) * 3 / 4);
    }

    @media (width < 1538px) {
      width: calc(100vw - 150px - 370px);
      min-width: calc(100vw - 150px - 370px);
      height: calc((100vw - 150px - 370px) * 3 / 4);
      max-height: calc((100vw - 150px - 370px) * 3 / 4);
    }

    @media (--desktop) {
      min-width: calc(100vw - 96px - 370px);
      width: calc(100vw - 96px - 370px);
      height: calc((100vw - 96px - 370px) * 3 / 4);
      max-height: calc((100vw - 96px - 370px) * 3 / 4);
    }

    @media (--desktopSmall) {
      min-width: calc(100vw - 80px);
      width: calc(100vw - 80px);
      height: calc((100vw - 80px) * 3 / 4);
      max-height: calc((100vw - 80px) * 3 / 4);
    }

    @media (--tablet) {
      width: calc(100vw);
      min-width: calc(100vw);
      height: calc((100vw) * 3 / 4);
      max-height: calc((100vw) * 3 / 4);
    }

    & picture {
      height: 100%;
    }

    & .mainFeaturesSliderItemButton {
      position: absolute;
      top: calc(100% - 112px);
      left: 80px;

      & svg {
        margin: 0 0 0 15px;
        stroke: var(--colorWhite);
      }
    }

    & .mainFeaturesSliderItemInfo {
      position: absolute;
      top: 64px;
      left: 80px;
      display: flex;
      flex-direction: column;

      & .mainFeaturesSliderItemLabel {
        padding: 10px;
        text-align: left;
        width: max-content;
        background-color: var(--colorBackgroundAccent);
      }

      & .mainFeaturesSliderItemTitle {
        padding: 30px;
        text-align: left;
        background: rgba(247, 246, 245, 0.9);

        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 43px;
      }
    }

    & img {
      width: 100%;
      display: block;
      object-fit: cover;
      height: 100%;
    }

    & p {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }
}
