@import '../../../../shared/styles/core.css';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  overflow: visible;
  width: 100%;
  max-width: 100vw;
  padding: 0;
  box-sizing: border-box;

  &:hover,
  &:focus-within {
    z-index: 60;
  }

  :global {
    .carousel-control--prev {
      display: none;
    }

    .carousel-control--next {
      display: none;
    }
  }
}

.loader {
  display: flex;
  flex-shrink: 0;
  gap: 12px;
  margin: 0;
  padding: 0;
  padding-left: 16px;
  list-style: none;
}

.wrapper {
  display: contents;
}

.title {
  box-sizing: border-box;
  width: 100%;
  max-width: 1520px;
  padding: 0 16px;
}

.carousel {
  width: 100%;
  max-width: 1520px;
  padding-left: 16px;

  & :global {
    .swiper-slide {
      width: 164px;
    }
  }
}

.slide {
  display: flex;
  flex-shrink: 0;
  width: 164px;
  min-height: 364px;
}

.picture {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  min-height: 228px;
}

@media (--screen-xs) {
  .loader {
    padding-left: 32px;
  }

  .title {
    padding: 0 32px;
  }

  .carousel {
    padding-left: 32px;

    & :global {
      .swiper-slide {
        width: 224px;
      }
    }
  }

  .slide {
    width: 224px;
    min-height: 454px;
  }
}

@media (--screen-lg) {
  .loader {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 24px;
    box-sizing: border-box;
    padding: 0 48px;
  }

  .slide {
    width: 100%;
    min-height: 503px;
  }

  .carousel {
    position: relative;
    grid-row: 2/3;
    grid-column: 2/3;
    width: min(calc(100vw - 96px), 1520px);
    margin: 0;
    padding: 0;

    & :global {
      .swiper-slide {
        width: auto;
      }
    }
  }

  .root {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-row-gap: 32px;
    grid-column-gap: 0;
    overflow: clip visible;

    & .carousel {
      overflow: visible;
    }

    & > :global(.swiper) > :global(.swiper-wrapper) > :global(.swiper-slide) {
      opacity: 0;
      visibility: hidden;
      transition: visibility 0.2s linear, opacity 0.2s linear;
    }

    & > :global(.swiper) > :global(.swiper-wrapper) > :global(.swiper-slide-visible) {
      opacity: 1;
      visibility: visible;
    }

    & :global {
      .carousel-control--prev {
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 200;
        grid-column: 1/2;
        border: 1px solid var(--default-grey-20, #f0f0f0);
        transform: translate(50%, -50%);
        display: flex;
      }

      .carousel-control--next {
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 200;
        grid-column: 3/4;
        border: 1px solid var(--default-grey-20, #f0f0f0);
        transform: translate(-50%, -50%);
        display: flex;
      }
    }
  }

  .title {
    grid-row: 1/2;
    grid-column: 2/3;
    width: min((calc(100vw - 96px), 1520px));
    padding: 0;
  }

  .loader {
    grid-row: 2/3;
    grid-column: 2/3;
    box-sizing: border-box;
    width: min(calc(100vw - 96px), 1520px);
    margin: 0;
    padding: 0;
  }
}

@media (--screen-xl) {
  .loader {
    box-sizing: content-box;
    max-width: 1520px;
  }

  .slide {
    min-height: 619px;
  }
}
