@import '../../styles/core.css';

.review {
  width: calc(100% - 24px);
  padding: 40px 90px 40px 90px;
  margin: 0 12px;
  border: 1px solid var(--colorBorderDefault);
  border: none;
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;

  @media (--tabletLandscape) {
    flex-direction: column;
    padding: 40px 64px;
  }

  @media (--tablet) {
    padding: 20px 32px;
  }
}

.textBlock {
  overflow: hidden;
  width: 100%;
}

.reviewText {
  width: 100%;
  overflow: hidden;
  max-height: 64px;
  position: relative;
}

.expanded {
  & .reviewText {
    max-height: none;
  }

  & .lineClamp3 {
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}

.collapsable {
  & .reviewText {
    &:after {
      content: '...';
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      height: 40px;
      position: absolute;
      font-size: 24px;
      line-height: 24px;
      bottom: 0;
      left: 0;
      background: rgb(247, 246, 245, 0.5);
      background: linear-gradient(to bottom, rgba(247, 246, 245, 0) 0%, rgba(247, 246, 245, 0.8) 50%, rgba(247, 246, 245, 1) 100%);

    }
  }
}

.reviewAuthor {
  margin-top: 8px;
}

.reviewManager {
  margin-top: 8px;
}

.reviewShop {
  margin-top: 8px;
}

.reviewCity {
  margin-top: 8px;
}

button.reviewButton {
  margin-top: 8px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--colorPrimary);
  border-bottom: 1px dotted var(--colorPrimary);
}
