@import '../../styles/core';


.mainMobileFeaturesContent {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -16px;
    padding: 0;
    background-color: var(--colorBackgroundPrimary);
    overflow: hidden;

    @media (max-width: 349px) {
        padding: 10px 4px;
    }

    & .mainMobileFeaturesContentHeader {
        padding: 8px 0 8px 0;

        font-family: Manrope, system-ui, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: var(--colorTextPrimary);
    }

    & .mainMobileFeaturesItems {
        display: flex;
        align-items: center;
        width: 100%;
        flex-shrink: 0;
        flex-grow: 1;
        justify-content: center;
        position: relative;
    }

    & .mainMobileFeaturesItem {
        height: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        width: 100%;
        left: calc(100% + 24px);
        position: absolute;

        font-family: Manrope, system-ui, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;

        color: #49494D;
        text-decoration: none;
        display: none;
        animation-name: mobileFeaturesAnimationOut;
        animation-duration: 0.2s;

        &.active {
            display: flex;
            left: 0;
            animation-name: mobileFeaturesAnimationIn;
        }

        @media (max-width: 349px) {
            padding: 0 4px;
        }

        & img {
            margin: 0 8px 0 0;
            object-fit: contain;
            display: block;
        }
    }
}

@keyframes mobileFeaturesAnimationIn {
    from {
        left: calc(100% + 24px);
    }

    to {
        left: 0;
    }
}

@keyframes mobileFeaturesAnimationOut {
    from {
        left: 0;
        display: flex;
    }

    90% {
        display: none;
    }

    to {
        left: calc(-100% - 24px);
    }
}
